<template>
  <div class="mem-view page--team-list">
    <div class="page-header">
      <span class="mem-title">
        {{ $t("your_teams") }}
      </span>
      <button
        class="mem-btn btn-primary"
        @click="$router.push({ name: 'teamCreate' })"
      >
        {{ $t("create") }}
      </button>
    </div>
    <div class="list-loading" v-if="loading">
      <div class="loading-line">
        <div class="animated"></div>
      </div>
      <div class="loading-line"></div>
      <div class="loading-line">
        <div class="animated"></div>
      </div>
    </div>
    <template v-else>
      <empty-list v-if="!myTeams.length">
        {{ $t("my_teams_empty_list") }}
      </empty-list>
      <div class="my-team-list" v-else>
        <team-card v-for="(team, index) in myTeams" :key="index" :item="team" />
      </div>
      <div class="help-text">
        <span>{{ $t("team_help_text") }} </span>
        <a
          href="https://spartanrace.zendesk.com/hc/en-us/articles/205329587-How-do-I-Create-or-Join-a-Team-"
          target="_blank"
          >{{ $t("click_learn_more") }}.</a
        >
      </div>
    </template>

    <div
      class="mobile-button-create"
      @click="$router.push({ name: 'teamCreate' })"
    >
      <v-icon class="icon"> mdi-plus-circle-outline</v-icon>
      {{ $t("create_new_team") }}
    </div>

    <div class="help-text-mobile">
      <span>{{ $t("team_help_text") }} </span>
      <a
        href="https://spartanrace.zendesk.com/hc/en-us/articles/205329587-How-do-I-Create-or-Join-a-Team-"
        target="_blank"
        >{{ $t("click_learn_more") }}.</a
      >
    </div>

    <div class="page-header table-header">
      <span class="mem-subtitle">{{ $t("discover_teams") }}</span>
      <div class="search-teams">
        <search-input
          v-model="search"
          v-on:input="onSearch"
          :placeholder="$t('search_name_or_city')"
        />
      </div>
    </div>
    <div class="table-loading" v-if="loading">
      <div class="loading-line">
        <div class="animated"></div>
      </div>
      <div class="loading-line"></div>
      <div class="loading-line">
        <div class="animated"></div>
      </div>
    </div>
    <template v-else>
      <div class="discover-loader" v-if="teamsLoading">
        <v-progress-circular indeterminate color="white" />
      </div>
      <template v-else>
        <template v-if="!teamsList.length">
          <empty-list v-if="!search" text="">
            {{ $t("el_public_teams_list") }}
          </empty-list>
          <empty-list v-else simplify>
            {{ $t("el_no_teams_found") }}
          </empty-list>
        </template>
        <v-data-table
          v-else
          class="teams-list-table"
          :headers="columns"
          :items="teamsList"
          hide-default-header
          hide-default-footer
        >
          <template v-slot:body="{ items }">
            <team-card
              v-for="(item, index) in items"
              :key="index"
              :item="item"
              :avatar-size="58"
              class="table-row"
              row-mode
            >
              <div class="controls" slot="controls">
                <base-button
                  class="button-join"
                  btn-type="secondary-dark"
                  :disabled="item.joined"
                  :loading="item.loading"
                  v-if="item.isPublic"
                  @click="join(item.id, index)"
                >
                  {{ item.joined ? $t("joined") : $t("join") }}
                </base-button>
              </div>
            </team-card>
          </template>
          <template v-slot:footer>
            <div class="table-pagination">
              <span class="page-info">
                {{ pagination.from || 1 }}-{{ pagination.to || 5 }} of
                {{ pagination.total }}
              </span>
              <div class="pagination-controls">
                <v-btn
                  class="pagination-icon"
                  :disabled="pagination.page === 1"
                  width="24"
                  height="24"
                  icon
                  @click="paginate(pagination.page - 1)"
                >
                  <v-icon size="16" dense>arrow_back_ios</v-icon>
                </v-btn>
                <v-btn
                  class="pagination-icon"
                  :disabled="pagination.page === pagination.totalPages"
                  width="24"
                  height="24"
                  icon
                  @click="paginate(pagination.page + 1)"
                >
                  <v-icon size="16" dense>arrow_forward_ios</v-icon>
                </v-btn>
              </div>
            </div>
          </template>
        </v-data-table>
      </template>
    </template>
    <team-community-modal
      :show="showCommunityGuidelinesModal"
      @onClose="showCommunityGuidelinesModal = false"
    />
  </div>
</template>
<script>
import { debounce } from "lodash";
import { mapActions, mapGetters, mapMutations } from "vuex";

import TeamCard from "@/components/TeamCard";
import EmptyList from "@/components/EmptyList";
import BaseButton from "@/components/base/BaseButton.vue";
import SearchInput from "./components/SearchInput.vue";

export default {
  name: "TeamsListView",
  components: {
    "team-community-modal": () =>
      import("@/components/onboarding/TeamCommunityModal.vue"),
    EmptyList,
    TeamCard,
    BaseButton,
    SearchInput,
  },
  data() {
    return {
      loading: false,
      teamsLoading: false,
      columns: [
        {
          text: "",
          align: "start",
          value: "icon",
        },
        {
          text: "",
          value: "title",
        },
      ],
      search: "",
      showCommunityGuidelinesModal: false,
    };
  },
  computed: {
    ...mapGetters([
      "userData",
      "isLoadedUserData",
      "teamsList",
      "myTeams",
      "teamsPagination",
    ]),
    pagination: {
      get() {
        return {
          ...this.teamsPagination,
          to: Math.min(this.teamsPagination.total, this.teamsPagination.to),
        };
      },
      set(val) {
        this.setPagination(val);
      },
    },
  },
  watch: {
    isLoadedUserData: {
      handler(isLoaded) {
        if (!isLoaded) return;

        if (
          !this.userData?.extra?.teams_onboarding &&
          !this.userData.unassigned_tickets_events?.length
        )
          this.showCommunityGuidelinesModal = true;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["loadTeamList", "loadMyTeams", "joinTeam"]),
    ...mapMutations({
      setPagination: "setPagination",
      setListItemField: "setListItemField",
    }),
    async loadData(pageConfig) {
      this.loading = true;
      await this.loadMyTeams();
      await this.loadAvailableTeams(pageConfig);
      this.loading = false;
    },
    async loadAvailableTeams(pageConfig) {
      this.teamsLoading = true;
      await this.loadTeamList(pageConfig);
      this.teamsLoading = false;
    },
    async join(teamId, index) {
      this.setListItemField({
        index,
        field: "loading",
        value: true,
      });
      await this.joinTeam({ teamId });
      await Promise.all([this.loadData(), this.loadMyTeams()]);
    },
    paginate(targetPage) {
      if (targetPage >= 1 && targetPage <= this.pagination.totalPages) {
        const pageConfig = {
          page: targetPage,
          perPage: 5,
        };
        if (this.search.length >= 4) pageConfig.search = this.search;
        this.loadAvailableTeams(pageConfig);
      }
    },
    onSearch: debounce(function () {
      if (!this.search) {
        this.loadAvailableTeams({
          page: 1,
          perPage: 5,
        });
      }
      if (this.search.length >= 3)
        this.loadAvailableTeams({
          page: 1,
          perPage: 5,
          search: this.search,
        });
    }, 3000),
  },
  async mounted() {
    await this.loadData();
  },
};
</script>
<style lang="scss">
.page--team-list {
  max-width: min(670px, 100vw);
  width: 100%;
  margin-right: auto;

  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include elementMarginBottom("M");
    &.table-header {
      @include elementMarginBottom("S");
      @media screen and (max-width: 499px) {
        flex-direction: column;
        align-items: flex-start;
      }
      .mem-subtitle {
        @media screen and (max-width: 499px) {
          @include elementMarginBottom("S");
        }
      }
    }
    .search-teams {
      width: 240px;
      @media screen and (max-width: 499px) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    .mem-btn {
      @media screen and (max-width: 499px) {
        display: none;
      }
    }
  }
  .my-team-list {
  }
  .help-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.35;
    margin-top: 38px;
    padding: 0 30px;
    @include elementMarginBottom("M");

    @media screen and (max-width: 499px) {
      display: none;
    }
  }

  .help-text-mobile {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.04em;
    padding: 0 24px;
    @include elementMarginBottom("M");
    @media screen and (min-width: 499px) {
      display: none;
    }
  }

  .mobile-button-create {
    display: none;
    justify-content: center;
    align-items: center;
    height: 100px;
    margin-top: 25px;
    margin-left: 16px;
    margin-right: 16px;
    font-size: 14px;
    background-color: #202020;
    text-transform: uppercase;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    @include elementMarginBottom("M");
    .icon {
      color: #8a8a8a;
      margin-right: 12px;
    }
    @media screen and (max-width: 499px) {
      display: flex;
    }
  }

  .teams-list-table {
    background-color: transparent !important;
    max-width: min(670px, 100%);
    width: 100%;

    .v-data-table__wrapper,
    .team-card {
      max-width: min(670px, 100%);
      overflow: hidden;
    }

    .v-data-footer {
      padding: 0;
      font-size: 14px;
    }

    .table-pagination {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1 0;
      width: 100%;
      height: 62px;

      .page-info {
        font-weight: 500;
        font-size: 14px;
        line-height: 1.2;
        text-align: right;
        color: #9d9d9d;
      }

      .pagination-controls {
        margin-left: 40px;

        .pagination-icon {
          cursor: pointer;

          &:hover:not(.v-icon--disabled) {
            color: #9d9d9d;
          }

          & + .pagination-icon {
            margin-left: 24px;
          }
        }
      }
    }

    @media screen and (min-width: 500px) and (max-width: 900px) {
      max-width: min(616px, 100%);
      .team-card {
        max-width: min(616px, calc(100% - 50px));
      }
    }

    @media screen and (max-width: 499px) {
      padding: 0 25px;
      box-sizing: border-box;

      .team-card {
        max-width: min(670px, calc(100vw - 50px));
      }
    }
  }

  // * Loaders

  .list-loading,
  .table-loading {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start !important;
    overflow: hidden;
    max-width: min(670px, 100vw);
    width: 100%;

    .loading-line {
      height: 122px;
      background-color: #020000;
      position: relative;
      overflow: hidden;

      .animated {
        -webkit-animation: loading-line 2s infinite;
        animation: loading-line 2s infinite;
        content: " ";
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        background: linear-gradient(
          110deg,
          rgba(0, 0, 0, 0),
          rgba(255, 255, 255, 0.05),
          rgba(255, 255, 255, 0.15) 70%,
          rgba(255, 255, 255, 0.05),
          rgba(0, 0, 0, 0)
        );
      }
    }
  }

  .list-loading {
    @include elementMarginBottom("M");
  }

  .table-loading .loading-line {
    height: 105px;
  }

  .discover-loader {
    display: flex;
    flex: 1 0;
    justify-content: center;
    align-items: center;
    color: white;
    height: 525px;
  }
}
</style>
